@font-face {
  font-family: 'Proxima Nova';
  src: url(./fonts/ProximaNova-Bold.woff) format('woff'),
    url(./fonts/ProximaNova-Bold.woff2) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./fonts/ProximaNova-Semibold.woff) format('woff'),
    url(./fonts/ProximaNova-Semibold.woff2) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./fonts/ProximaNova-Medium.woff) format('woff'),
    url(./fonts/ProximaNova-Medium.woff2) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./fonts/ProximaNova-Regular.woff) format('woff'),
    url(./fonts/ProximaNova-Regular.woff2) format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@-webkit-keyframes disappear {
  0% {-webkit-background-color: red}
  100% {-webkit-background-color: yellow}
}

.rt-noData {
  -webkit-animation-name: disappear !important;
  -webkit-animation-duration: 1s !important;
}

.ReactTable .rt-thead .rt-th {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: left;
  padding: 12px 8px 12px 8px;
  outline: none;
  line-height: 1.0;
}

.ReactTable .rt-thead.-filters .rt-th {
  align-items: flex-start !important;
}

.ReactTable .rt-tbody .rt-td {
  padding: 12px 8px 12px 8px;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border: 1px solid rgba(0,0,0,0.02);
}

.ReactTable .rt-thead .rt-resizable-header-content {
  line-height: 1.25;
}

.ReactTable .rt-thead.-filters input {
  background-color: #f0f0f0;
}

.ReactTable .rt-thead.-filters .date-picker label {
  /* transform: translate(7px, 7px) scale(1); */
}

.ReactTable .rt-thead.-filters .date-picker input {
  border: none;
}

.ReactTable .rt-thead.-filters select {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' view-box='0 0 16 16' width='16' height='16' fill='%23000000'><polygon points='0,4 12,4 7,12'/></svg>") center right no-repeat;
  background-color: #f0f0f0;
}

.ReactTable .-sort-desc {
  box-shadow: none !important;
}
.ReactTable .-sort-desc::before {
  content: "▼";
  float: right;
}

.ReactTable .-sort-asc {
  box-shadow: none !important;
}

.ReactTable .-sort-asc::before {
  content: "▲";
  float: right;
}

a {
  text-decoration: underline;
}

label {
  font-size: 16px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: 600;
  line-height: 1.6;
}

h3 {
  font-weight: 900px;
  font-size: 30px;
  font-family: 'Proxima Nova', sans-serif;
}

.fsp-notifications__container {
  position: fixed !important;
}
